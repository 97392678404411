// import { useEffect, useState } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Homepage from './components/Homepage';
import Aboutus from './components/Aboutus';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ProjectGallery from './components/ProjectGallery';

const router = createBrowserRouter([
  {
    path: `/`,
    element: <Homepage />
    //   children: [{ path: '404', element: <NotFound /> }]
  },
  {
    path: `/about`,
    element: <Aboutus />
  },
  {
    path: `/projects`,
    element: <Projects />
  },
  {
    path: `/projects/:projectID`,
    element: <ProjectGallery />
  },
  {
    path: `/contact`,
    element: <Contact />
  }
]);

export default router;
