import React, { useEffect } from 'react';
import './projects.css';
// import bg_img from '../images/contact-bg.avif';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import projectList from '../images/ProjectImages';
import Navigation from './Navigation';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Navigation navigateTabValue={2} />
      <Box sx={{ minHeight: '100vh' }}>
        <section>
          <Container sx={{ padding: 5 }}>
            <Typography
              sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: '1.7rem' }}
            >
              Recent Projects
            </Typography>
          </Container>
        </section>
        <section>
          <Grid
            container
            sx={{ padding: 5 }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {projectList.map((proj, index) => (
              <Grid sx={{ '&:hover': { color: '#3366cc' } }} item xs={4} sm={4} md={3} key={index}>
                <Paper
                  sx={{
                    height: 200,
                    borderRadius: 1,
                    overflow: 'hidden'
                    //   boxShadow: '0px 0px 10px 0.9px #ddd'
                  }}
                >
                  <img
                    onClick={() => {
                      navigate(`/projects/${index}`);
                    }}
                    className="projects-img"
                    src={proj.mainImg}
                    alt=""
                  />
                </Paper>
                <Typography sx={{ color: 'inherit' }}>{proj.title}</Typography>
              </Grid>
            ))}
          </Grid>
        </section>
      </Box>
      <Footer />
    </>
  );
}
