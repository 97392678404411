import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './homepage.css';
import pic0 from '../images/homepage/p0.jpg';
import pic01 from '../images/homepage/p1.jpg';
import pic02 from '../images/homepage/p2.jpg';
import pic03 from '../images/homepage/p3.jpg';
import pic04 from '../images/homepage/p4.jpg';
import pic05 from '../images/homepage/home1.jpg';
import pic06 from '../images/homepage/home2.jpg';
import bdm_logo from '../images/bdm-logo2.png';
import { Box, Typography } from '@mui/material';
import Navigation from './Navigation';

export default function Homepage() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Navigation navigateTabValue={0} />
      <Box sx={{ maxHeight: '100%', overflow: 'hidden', position: 'relative' }}>
        <Carousel
          interval={5000}
          pause={false}
          controls={false}
          fade
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <img className="carousel-img" src={pic0} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic01} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic06} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic05} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic02} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic03} alt="slide-img" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="carousel-img" src={pic04} alt="slide-img" />
          </Carousel.Item>
        </Carousel>
        <Box
          sx={
            window.innerWidth > 500
              ? { position: 'absolute', top: '36%', left: 70, zIndex: 5, color: '#fff' }
              : { position: 'absolute', top: '18%', left: 40, zIndex: 5, color: '#fff' }
          }
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                letterSpacing: '1px',
                color: '#eee',
                fontWeight: '600',
                fontSize: window.innerWidth > 500 ? '2rem' : '1.5rem'
              }}
              className="homeTitle"
            >
              Where Innovation
            </Typography>
            <Typography
              sx={{
                textTransform: 'uppercase',
                letterSpacing: '1px',
                color: '#eee',
                fontWeight: '600',
                fontSize: window.innerWidth > 500 ? '2rem' : '1.5rem'
              }}
              className="homeTitle"
            >
              Meets Architectural
            </Typography>
            <Typography
              sx={{
                textTransform: 'uppercase',
                letterSpacing: '1px',
                color: '#eee',
                fontWeight: '600',
                fontSize: window.innerWidth > 500 ? '2rem' : '1.5rem'
              }}
              gutterBottom
              className="homeTitle"
            >
              Excellence.
            </Typography>
          </motion.div>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '91%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 5,
            color: '#fff'
          }}
        >
          <motion.div
            initial={{ opacity: 0.5, transform: 'translateY(400px)' }}
            transition={{ duration: 0.5, delay: 1 }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
          >
            {/* <Typography sx={{ textAlign: 'center', fontSize: 13, letterSpacing: 2 }}>
            B.D. Malik & Associates, led by A.R. Devendra Malik, excels in master planning, with a
            focus on education and healthcare projects nationwide.
          </Typography> */}
            {/* <Typography
              sx={{
                textTransform: 'uppercase',
                color: '#1976d2',
                textAlign: 'center',
                fontWeight: 800,
                fontSize: 16,
                letterSpacing: 1
              }}
            >
              Bhanti Devi Malik & Associates
            </Typography> */}
            <Typography
              sx={{
                textTransform: 'uppercase',
                letterSpacing: '0.8px',
                textAlign: 'center',
                fontSize: 13
              }}
            >
              (B.D. Malik & Associates)
            </Typography>
          </motion.div>
        </Box>
        <Box sx={{ position: 'absolute', top: 5, left: 20, zIndex: 5, color: '#fff' }}>
          <img style={{ height: '80px', filter: 'invert()' }} src={bdm_logo} alt="BDM logo" />
        </Box>
      </Box>
    </>
  );
}
