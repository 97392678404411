import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './aboutus.css';
import article01 from '../images/Articles/article1.jpg';
import article02 from '../images/Articles/article2.jpeg';
import article03 from '../images/Articles/article3.jpeg';
import article04 from '../images/Articles/article4.jpeg';
import article05 from '../images/Articles/article5.jpeg';
import aboutus01 from '../images/aboutus/1.jpg';
import aboutus02 from '../images/aboutus/2.jpg';
import aboutus03 from '../images/aboutus/3.jpg';
import aboutus04 from '../images/aboutus/4.jpg';
import aboutus05 from '../images/aboutus/5.jpg';
import aboutus_bg from '../images/aboutus_bg.jpg';
import { Close } from '@mui/icons-material';
import Navigation from './Navigation';
import Footer from './Footer';

export default function Aboutus() {
  const [animationPlayed, setAnimationPlayed] = useState({ anim1: false, anim2: false });
  const [fullscreenImg, setFullscreenImg] = useState({ dialog: false, img: null, img_title: null });

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const element1 = document.getElementById('animate-elm1');
      const element2 = document.getElementById('animate-elm2');
      const threshold = (window.innerHeight / 4) * 3;

      if (element1 && !animationPlayed.anim1) {
        const rect = element1.getBoundingClientRect();

        if (rect.top <= threshold) {
          setAnimationPlayed((prev) => ({ ...prev, anim1: true }));
        }
      }
      if (element2 && !animationPlayed.anim2) {
        const rect = element2.getBoundingClientRect();
        if (rect.top <= threshold) {
          setAnimationPlayed((prev) => ({ ...prev, anim2: true }));
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationPlayed]);

  const articlesImgData = [
    {
      img: article01,
      title: 'article01'
    },
    {
      img: article02,
      title: 'article02'
    },
    {
      img: article03,
      title: 'article03'
    },
    {
      img: article04,
      title: 'article04'
    },
    {
      img: article05,
      title: 'article05'
    }
  ];

  return (
    <>
      <Navigation navigateTabValue={1} />
      <Box className="aboutus-box" sx={{ background: '#000', minHeight: '100vh' }}>
        {fullscreenImg.img !== null && (
          <Dialog
            maxWidth="lg"
            onClose={() => {
              setFullscreenImg({ dialog: false, img: null, img_title: null });
            }}
            open={fullscreenImg.img !== null && fullscreenImg.dialog}
          >
            <Paper elevation={4}>
              <DialogTitle sx={{ m: 0, p: 0, height: 35, background: '#333' }}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setFullscreenImg({ dialog: false, img: null, img_title: null });
                  }}
                  size="small"
                  sx={{
                    color: (theme) => theme.palette.grey[500]
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ height: '80vh', background: '#ddd' }} dividers>
                <img
                  style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                  src={fullscreenImg.img}
                  alt={fullscreenImg.img_title}
                />
              </DialogContent>
            </Paper>
          </Dialog>
        )}
        <Box
          sx={{
            width: '100%',
            height: window.innerWidth > 500 ? '70vh' : '55vh',
            overflow: 'hidden'
          }}
        >
          <Carousel
            interval={5000}
            pause={false}
            indicators={false}
            controls={false}
            fade
            activeIndex={index}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus_bg} alt="slide-img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus01} alt="slide-img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus02} alt="slide-img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus03} alt="slide-img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus04} alt="slide-img" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="abt-carousel-img" src={aboutus05} alt="slide-img" />
            </Carousel.Item>
          </Carousel>
          {/* <motion.img
            initial={{ scale: 1, opacity: 0 }}
            animate={{ scale: 1.1, opacity: 1 }}
            transition={{ duration: 1 }}
            style={{ objectFit: 'cover', height: '100%', width: '100%' }}
            src={aboutus_bg}
            alt=""
          /> */}
        </Box>
        <Container sx={{ padding: 5 }}>
          <Box>
            <Divider
              textAlign="center"
              sx={{
                color: '#fff',
                fontSize: window.innerWidth > 500 ? '2.5rem' : '1.9rem',
                textTransform: 'uppercase',
                fontWeight: 500,
                margin: '4.5rem 0rem',
                '&:before': { borderColor: '#fff!important' },
                '&:after': { borderColor: '#fff!important' }
              }}
            >
              About Us
            </Divider>
            <motion.div
              id="animate-elm1"
              style={{ margin: window.innerWidth > 500 ? '3.5rem 2.5rem' : '3.5rem 0.5rem' }}
              initial={{ transform: 'translateY(70px)', opacity: 0 }}
              animate={{
                transform: animationPlayed.anim1 ? 'translateY(0px)' : 'translateY(70px)',
                opacity: animationPlayed.anim1 ? 1 : 0
              }}
              transition={{ duration: 1 }}
            >
              <Typography sx={{ letterSpacing: '1.5px', textAlign: 'justify' }} gutterBottom>
                <b className="theme-text">
                  Bhanti Devi Malik & Associates (B.D. Malik & Associates)
                </b>{' '}
                was founded by A.R. Devendra Malik. Based out of New Delhi, it is an established and
                renowned master planning, architectural and interior design practice with a
                pan-India presence and a diverse body of work across varied scales and typologies in
                both public and private sectors. With its strong foothold in educational and
                healthcare projects and a diverse portfolio supported by commercial, residential and
                public building projects, BDM is committed to making well-designed spaces accessible
                to all.
              </Typography>
            </motion.div>
            <motion.div
              id="animate-elm2"
              style={{ margin: window.innerWidth > 500 ? '3.5rem 2.5rem' : '3.5rem 0.5rem' }}
              initial={{ transform: 'translateY(70px)', opacity: 0 }}
              animate={{
                transform: animationPlayed.anim2 ? 'translateY(0px)' : 'translateY(70px)',
                opacity: animationPlayed.anim2 ? 1 : 0
              }}
              transition={{ duration: 1 }}
            >
              <Typography sx={{ letterSpacing: '1.5px', textAlign: 'justify' }}>
                As an integrated design practice, BDM is driven by the ideology of creating
                research-informed, process-driven, and future-ready designs that positively impact
                the built environment. We are a collaborative practice providing holistic solutions
                to spatial issues through architecture, interior design and planning interventions.
              </Typography>
            </motion.div>
          </Box>
          <Box>
            <Divider
              textAlign="center"
              sx={{
                color: '#fff',
                fontSize: '2.5rem',
                textTransform: 'uppercase',
                fontWeight: 500,
                margin: '4.5rem 0rem',
                '&:before': { borderColor: '#fff!important' },
                '&:after': { borderColor: '#fff!important' }
              }}
            >
              Articles
            </Divider>
            <Container>
              <ImageList variant="masonry" cols={4} gap={8}>
                {articlesImgData.map((item) => (
                  <ImageListItem key={item.img}>
                    <img
                      className="article-img"
                      onClick={() => {
                        setFullscreenImg({ dialog: true, img: item.img, img_title: item.title });
                      }}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Container>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
