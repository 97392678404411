import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Navigation from './Navigation';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { CalendarViewWeek, GridView, ViewDay, ViewModule } from '@mui/icons-material';
import projectList from '../images/ProjectImages';
import { useParams } from 'react-router-dom';
import Footer from './Footer';

export default function ProjectGallery() {
  const [imageGridSize, setImageGridSize] = useState(4);
  const { projectID } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const children = [
    <ToggleButton
      onClick={() => {
        setImageGridSize(1);
      }}
      value="1"
      key="1"
    >
      <ViewDay />
    </ToggleButton>,
    <ToggleButton
      onClick={() => {
        setImageGridSize(2);
      }}
      value="2"
      key="2"
    >
      <GridView />
    </ToggleButton>,
    <ToggleButton
      onClick={() => {
        setImageGridSize(3);
      }}
      value="3"
      key="3"
    >
      <ViewModule />
    </ToggleButton>,
    <ToggleButton
      onClick={() => {
        setImageGridSize(4);
      }}
      value="4"
      key="4"
    >
      <CalendarViewWeek />
    </ToggleButton>
  ];

  return (
    <>
      <Navigation navigateTabValue={2} />
      <Box
        sx={{
          minHeight: '100vh',
          minWidth: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          gap: '3.5rem'
        }}
      >
        <Box
          sx={{
            background: 'grey',
            color: 'black',
            padding: '0.5rem .8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: '10px',
            minWidth: '60%',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          }}
        >
          <Box>
            <Typography
              sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.85rem' }}
            >
              Project Name:{' '}
              <span style={{ color: '#0055aa', textShadow: '0 0 #000', wordBreak: 'break-all' }}>
                {projectList[Number(projectID)].title}
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: '10px'
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.85rem' }}
            >
              Image Size:
            </Typography>
            <ToggleButtonGroup
              sx={{
                '& button': { padding: '7px' },
                '& .Mui-selected': { background: '#1976d250 !important' },
                '& svg': { height: '16px', width: '16px' }
              }}
              value={String(imageGridSize)}
              size="small"
              aria-label="Small sizes"
            >
              {children}
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Box sx={{ width: window.innerWidth > 500 ? '80%' : '75%' }}>
          <Box sx={{ margin: '1rem 0rem' }}>
            {projectList[Number(projectID)]?.desc &&
              projectList[Number(projectID)]?.desc.map((item, index) => (
                <Typography
                  key={index}
                  sx={{ marginBottom: 3, letterSpacing: '1.3px', textAlign: 'justify' }}
                >
                  {item}
                </Typography>
              ))}
          </Box>
          <ImageList variant="masonry" cols={imageGridSize} gap={8}>
            {projectList[Number(projectID)].gallery.map((item, index) => (
              <ImageListItem key={index}>
                <img
                  style={{ maxHeight: '80vh', maxWidth: '80vw', objectFit: 'contain' }}
                  //   srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={item}
                  alt={item}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
