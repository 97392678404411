import proj1 from './Recent Projects/Deen Bandhu Complex , Rohtak/main.jpg';
import proj2 from './Recent Projects/Dr.Pathak Residence , Janakpuri/main.jpg';
import proj3 from './Recent Projects/Kalaveer Public School , Panchkula/main.jpg';
import proj4 from './Recent Projects/Maccure Hospital , Janakpuri/main.jpg';
import proj5 from './Recent Projects/Mr.Sanjay Residence , Janakpuri/main.jpg';
import proj6 from './Recent Projects/Sai Square Mall , Rohtak/main.jpg';
import proj7 from './Recent Projects/Sanjevani Hospital , Kiran Garden/main.jpg';
import proj8 from './Recent Projects/Swastik Multispeciality Hospital ,Sampla/main.jpg';
import proj9 from './Recent Projects/Others/main.JPG';

const importAll = (r) => r.keys().map(r);
const proj1_images = importAll(
  require.context(
    './Recent Projects/Deen Bandhu Complex , Rohtak/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj2_images = importAll(
  require.context(
    './Recent Projects/Dr.Pathak Residence , Janakpuri/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj3_images = importAll(
  require.context(
    './Recent Projects/Kalaveer Public School , Panchkula/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj4_images = importAll(
  require.context(
    './Recent Projects/Maccure Hospital , Janakpuri/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj5_images = importAll(
  require.context(
    './Recent Projects/Mr.Sanjay Residence , Janakpuri/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj6_images = importAll(
  require.context(
    './Recent Projects/Sai Square Mall , Rohtak/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj7_images = importAll(
  require.context(
    './Recent Projects/Sanjevani Hospital , Kiran Garden/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj8_images = importAll(
  require.context(
    './Recent Projects/Swastik Multispeciality Hospital ,Sampla/gallery',
    false,
    /\.(png|jpe?g|svg|JPG)$/
  )
);
const proj9_images = importAll(
  require.context('./Recent Projects/Others/gallery', false, /\.(png|jpe?g|svg|JPG)$/)
);

const projectList = [
  {
    mainImg: proj1,
    title: 'Deen Bandhu Complex, Rohtak',
    gallery: proj1_images,
    desc: [
      `The project is based in Sir Chotu Ram Park, Rohtak. This Project was about making multiple large span multipurpose halls which can accommodate many people for events like wedding, receptions, birthday / anniversary parties etc. 
  `,
      `As it was being constructed in a historical land under such a respected & known name, thus it was required to match the surroundings and fit in by giving a sense of a structure that has been designed & constructed in a manner that follow our historical architecture style elements like arches, exposed brickwork , grand common spaces and combining it with steel structure to provide large open hall spans with less restriction in between thus resulting in a majestic building which will be seen and remembered by new generations to come as a landmark.
  `
    ]
  },
  {
    mainImg: proj2,
    title: 'Dr.Pathak Residence, Janakpuri',
    gallery: proj2_images,
    desc: [
      'The project is based in D-2/88, Janakpuri and status is under construction. It is a turnkey project with basement, stilt parking and four residential floors. There are two lifts provided where one acts as a personal lift as asked by the client.'
    ]
  },
  {
    mainImg: proj3,
    title: 'Kalaveer Public School, Panchkula',
    gallery: proj3_images,
    desc: [
      'The project is based near Panchkula , status in under construction and the main aim in this project was to build a school with an academy block for students to stay and coaching for defence exams both written and physical thus outdoor activities was also a top priority for the client.'
    ]
  },
  { mainImg: proj4, title: 'Maccure Hospital, Janakpuri', gallery: proj4_images },
  { mainImg: proj5, title: 'Mr.Sanjay Residence, Janakpuri', gallery: proj5_images },
  { mainImg: proj6, title: 'Sai Square Mall, Rohtak', gallery: proj6_images },
  { mainImg: proj7, title: 'Sanjevani Hospital, Kiran Garden', gallery: proj7_images },
  { mainImg: proj8, title: 'Swastik Multispeciality Hospital', gallery: proj8_images },
  { mainImg: proj9, title: 'Others', gallery: proj9_images }
];

export default projectList;
