import React from 'react';
import './footer.css';
import { Facebook, Instagram, LinkedIn, X } from '@mui/icons-material';

export default function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Copyright &copy; 2024 All Rights Reserved by&nbsp;
              <a href="/">(B.D. Malik & Associates)</a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a className="facebook" href="/">
                  <Facebook />
                </a>
              </li>
              <li>
                <a className="twitter" href="/">
                  <X />
                </a>
              </li>
              <li>
                <a className="instagram" href="/">
                  <Instagram />
                </a>
              </li>
              <li>
                <a className="linkedin" href="/">
                  <LinkedIn />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
