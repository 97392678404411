import { Box, Typography } from '@mui/material';
import bg_img from '../images/contact-bg.avif';
import { motion } from 'framer-motion';
import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';

export default function Contact() {
  return (
    <>
      <Navigation navigateTabValue={3} />
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img className="contact-bg-img" src={bg_img} alt="" />
        <Box
          className="contactus-box"
          sx={
            window.innerWidth > 600
              ? {
                  minHeight: '80vh',
                  minWidth: '80vw',
                  height: '80vh',
                  width: '80vw',
                  maxHeight: '90vh',
                  maxWidth: '90vw',
                  background: '#1a1a1a',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2
                }
              : {
                  overflow: 'scroll',
                  minHeight: '80vh',
                  minWidth: '80vw',
                  maxHeight: '90vh',
                  maxWidth: '90vw',
                  background: '#1a1a1a',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  zIndex: 2
                }
          }
        >
          <motion.div
            style={{ height: '100%', maxWidth: window.innerWidth > 600 ? '45%' : '100%' }}
          >
            <Box
              sx={{
                padding: '30px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <motion.div
                transition={{ duration: 0.45, ease: 'easeOut' }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <Typography
                  sx={
                    window.innerWidth > 600
                      ? { fontSize: '4rem', fontWeight: '300', lineHeight: '4.5rem' }
                      : {
                          fontSize: '1.5rem',
                          fontWeight: '300',
                          textAlign: 'center'
                        }
                  }
                >
                  Lets build something{' '}
                  <span className="theme-text" style={{ fontWeight: 600, display: 'block' }}>
                    great together.
                  </span>
                </Typography>
              </motion.div>
              <Box sx={{ flex: 1, marginTop: 4, width: '100%' }}>
                <iframe
                  title="mapBox"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.5076871537535!2d77.07755684852597!3d28.61454254921851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04b5b6058f0d%3A0x6db0f3ff139172cc!2sB.D.Malik%20%26%20Associates!5e0!3m2!1sen!2sin!4v1709473031038!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Box>
          </motion.div>

          <motion.div
            style={
              window.innerWidth > 600
                ? {
                    height: '100%',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                  }
                : {
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex'
                  }
            }
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.9, delay: 0.5 }}
              style={{
                padding: '10px',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box sx={{ marginBottom: '30px' }}>
                <Typography
                  sx={{ textAlign: 'center', fontSize: '1rem', textTransform: 'uppercase' }}
                >
                  We’d love to hear from you!
                </Typography>
                <Typography
                  sx={{ textAlign: 'center', fontSize: '1rem', textTransform: 'uppercase' }}
                >
                  Give us call, send us a message or find us on social media.
                </Typography>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '20px',
                  flexDirection: 'column'
                }}
              >
                <Typography
                  sx={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '1.1rem' }}
                >
                  Address:{' '}
                  <Typography component={'span'} style={{ display: 'block', fontSize: '0.9em' }}>
                    D-28, Sita Puri, Pankha Road New Delhi - 110045
                  </Typography>
                </Typography>
                <Typography
                  sx={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '1.1rem' }}
                >
                  Contact:{' '}
                  <Typography component={'span'} style={{ display: 'block', fontSize: '0.9em' }}>
                    9811472785 , 8287085362
                  </Typography>
                </Typography>
                <Typography
                  sx={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '1.1rem' }}
                >
                  General Enquiry:{' '}
                  <Typography component={'span'} style={{ display: 'block', fontSize: '0.9em' }}>
                    gauravgm11malik@gmail.com
                  </Typography>
                </Typography>
              </Box>
            </motion.div>
          </motion.div>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
