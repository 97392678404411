import { Apartment, Assignment, Home, KeyboardArrowUp, Phonelink } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function Navigation({ navigateTabValue }) {
  const [topBtn, setTopBtn] = useState(false);
  const navigate = useNavigate();
  const navTabStyles = { p: 0, height: 20, minWidth: 20, color: 'black' };

  useEffect(() => {
    const handlePageScroll = () => {
      if (window.scrollY > 300) {
        setTopBtn(true);
      } else {
        setTopBtn(false);
      }
    };

    window.addEventListener('scroll', handlePageScroll);

    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 100,
          background: '#fff',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          opacity: 0.95
        }}
      >
        <Tabs
          orientation="vertical"
          value={navigateTabValue}
          aria-label="navigation menu"
          sx={{ width: 40, p: 0 }}
        >
          <Tooltip placement="left" title="Home Page">
            <Tab
              onClick={() => {
                navigate('/');
              }}
              sx={navTabStyles}
              icon={<Home />}
              aria-label="home"
            />
          </Tooltip>
          <Tooltip placement="left" title="About Us">
            <Tab
              onClick={() => {
                navigate('/about');
              }}
              sx={navTabStyles}
              icon={<Apartment />}
              aria-label="about"
            />
          </Tooltip>
          <Tooltip placement="left" title="Our Projects">
            <Tab
              onClick={() => {
                navigate('/projects');
              }}
              sx={navTabStyles}
              icon={<Assignment />}
              aria-label="projects"
            />
          </Tooltip>
          <Tooltip placement="left" title="Contact Us">
            <Tab
              onClick={() => {
                navigate('/contact');
              }}
              sx={navTabStyles}
              icon={<Phonelink />}
              aria-label="contact"
            />
          </Tooltip>
        </Tabs>
      </Box>
      {topBtn && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.3, type: 'spring' }}
          animate={{ opacity: 1, scale: 1 }}
          style={{
            position: 'fixed',
            bottom: 50,
            right: 50,
            transform: 'translateY(-50%,-50%)',
            zIndex: 100,
            background: '#fff',
            borderRadius: '50%',
            opacity: 0.95
          }}
        >
          <IconButton
            size="medium"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}
            sx={{ color: '#000' }}
            aria-label="top-button"
          >
            <KeyboardArrowUp />
          </IconButton>
        </motion.div>
      )}
    </>
  );
}
